var _a;
import * as React from 'react';
import { BigPet, Bike, MediumPet, Serfing, SmallPet, SnowBoard, Ski, PetInHold, SportEquipment } from '@websky/core/src/Icons';
import { BaggageType } from '@websky/graphql';
var Paid = function () { return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.5284 13.3008H13.0057C13.8807 13.3008 14.6136 13.1426 15.2045 12.8262C15.7992 12.5059 16.2462 12.0723 16.5455 11.5254C16.8485 10.9746 17 10.3555 17 9.66797C17 8.98828 16.8504 8.37109 16.5511 7.81641C16.2557 7.26172 15.8106 6.82031 15.2159 6.49219C14.625 6.16406 13.8883 6 13.0057 6H8.77841V11.7656H7V13.3008H8.77841V14.3086H7V15.8496H8.77841V18H10.5284V15.8496H13.0909V14.3086H10.5284V13.3008ZM10.5284 11.7656H12.983C13.4981 11.7656 13.9242 11.6777 14.2614 11.502C14.5985 11.3223 14.8485 11.0762 15.0114 10.7637C15.1742 10.4473 15.2557 10.084 15.2557 9.67383C15.2557 9.26758 15.1742 8.9043 15.0114 8.58398C14.8485 8.26367 14.6004 8.01172 14.267 7.82812C13.9337 7.64062 13.5133 7.54688 13.0057 7.54688H10.5284V11.7656Z", fill: "currentColor" }))); };
var typesByRfics = {
    '0EF': SnowBoard,
    '0EG': SnowBoard,
    '0EH': SnowBoard,
    '0EC': Bike,
    '0EI': Serfing,
    '0DD': SnowBoard,
    '0BT': PetInHold,
    '021': SmallPet,
    '0BS': MediumPet,
    '0AY': BigPet,
    '0F8': Ski
};
export var overridedIcons = {
    Paid: Paid
};
var iconByBaggageType = (_a = {},
    _a[BaggageType.PetInCabin] = PetInHold,
    _a);
export var renderHeaderEquipmentIcon = function (rfics, baggageType) {
    var _a;
    if (baggageType && iconByBaggageType[baggageType]) {
        return iconByBaggageType[baggageType];
    }
    return (_a = typesByRfics[rfics]) !== null && _a !== void 0 ? _a : SportEquipment;
};
