import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale } from '@websky/core/src/utils';
import Link from '@websky/core/src/Link';
export var renderCheckoutLinksLabel = function (companyInfo) {
    var t = useTranslation('Checkout').t;
    var currentLocale = getLocale();
    var subsidiesLocaleLink = "https://www.iflyltd.ru/".concat(currentLocale, "/subsidies/");
    return (React.createElement(React.Fragment, null,
        t('I accept the'),
        ' ',
        React.createElement(Link, { action: "https://iflyltd.ru/docs/rules.pdf", target: "_blank" }, t('airline rules')),
        ",",
        ' ',
        React.createElement(Link, { action: function () { } },
            React.createElement("span", { "data-type": "termsOfTariff" }, t('terms of tariff'))),
        ",",
        ' ',
        React.createElement(Link, { action: "https://www.iflyltd.ru/docs/rules.pdf?1718806358", target: "_blank" }, t('rules for purchasing additional services')),
        t('as well as the'),
        React.createElement(Link, { action: subsidiesLocaleLink, target: "_blank" }, t('rules for purchasing subsidized tariffs')),
        ".",
        ' ',
        companyInfo.privacyPolicy && (React.createElement(React.Fragment, null,
            t('I consent to the processing and storage of personal data in accordance with'),
            React.createElement(Link, { target: '_blank', action: companyInfo.privacyPolicy }, t('processing of my personal data')),
            "."))));
};
