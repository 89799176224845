import { __read } from "tslib";
import * as React from 'react';
import { initI18n } from '@websky/core/src/utils';
import Seat from '@websky/core/src/SeatMap/components/SeatMap/Seat/Seat';
import { Money } from '@websky/core/src/Money';
import css from './SeatsInfo.css';
import { useTranslation } from 'react-i18next';
import FreeSeatInfo from '@websky/core/src/SeatMap/components/SeatsInfo/FreeSeatInfo/FreeSeatInfo';
initI18n('SeatMap');
var SeatsInfo = function (_c) {
    var seatsByRfisc = _c.seatsByRfisc, passenger = _c.passenger, _d = _c.filterUnavailable, filterUnavailable = _d === void 0 ? true : _d, _e = _c.showComfort, showComfort = _e === void 0 ? true : _e, showFreeSeat = _c.showFreeSeat;
    var t = useTranslation('SeatMap').t;
    return (React.createElement(React.Fragment, null,
        Object.entries(seatsByRfisc)
            .filter(function (_c) {
            var _d = __read(_c, 2), _ = _d[0], seat = _d[1];
            return (filterUnavailable ? seat.isAvailable : true);
        })
            .sort(function (_c, _d) {
            var _e, _f, _g, _h;
            var _j = __read(_c, 2), _a = _j[0], a = _j[1];
            var _k = __read(_d, 2), _b = _k[0], b = _k[1];
            return ((_f = (_e = b.price) === null || _e === void 0 ? void 0 : _e.amount) !== null && _f !== void 0 ? _f : 0) - ((_h = (_g = a.price) === null || _g === void 0 ? void 0 : _g.amount) !== null && _h !== void 0 ? _h : 0);
        })
            .map(function (_c) {
            var _d = __read(_c, 2), _ = _d[0], seat = _d[1];
            return (React.createElement("div", { key: seat.number, className: css.service },
                seat.serviceImage && React.createElement("img", { className: css.image, src: seat.serviceImage, alt: seat.name }),
                React.createElement("div", { className: css.card },
                    React.createElement("div", { className: css.info },
                        React.createElement(Seat, { className: css.seat, seat: seat, onSelect: function () { return null; }, isAvailable: true, occupiedBy: null, passenger: passenger, passengerId: passenger.id, isSmall: true }),
                        showComfort && (seat === null || seat === void 0 ? void 0 : seat.seatService) && (React.createElement("div", { className: css.comfort }, t("SeatMap_".concat(seat.seatService.comfort)))),
                        seat.price && React.createElement(Money, { moneyClassName: css.money, money: seat.price })),
                    seat.description && React.createElement("div", { className: css.description }, seat.description))));
        }),
        showFreeSeat && (React.createElement(FreeSeatInfo, { customTitle: t('SeatMap_Comfort'), className: css.freeSeatContainer, seatClassName: css.freeSeatItem }))));
};
export default SeatsInfo;
