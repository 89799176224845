import { __assign } from "tslib";
import DesktopFlightRouteModal from './components/DesktopFlightRouteModal/DesktopFlightRouteModal';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService';
import { renderCheckoutLinksLabel } from './components/InfoLinksLabel/InfoLinksLabel';
import SeatMapService from './components/SeatMap/SeatMap';
import SpecialEqupmentBaggageCard from './components/Baggage/SpecialEqupment/BaggageCard';
import { overridedIcons, renderHeaderEquipmentIcon } from './components/Icons/overridedIcons';
import Passengers from './components/SeatMap/Passengers/Passengers';
import OrderInsuranceBlockSlotUpsale from '@websky/core/src/Checkout/components/Checkout/OrderInsuranceBlockSlot/OrderInsuranceBlockSlot.upsale';
export var renders = __assign(__assign({ DesktopFlightRouteModal: DesktopFlightRouteModal, CheckoutAdditionalService: CheckoutAdditionalService, renderCheckoutLinksLabel: renderCheckoutLinksLabel, SeatMapService: SeatMapService, Baggage: {
        SpecialEqupment: {
            BaggageCard: SpecialEqupmentBaggageCard
        }
    } }, overridedIcons), { renderHeaderEquipmentIcon: renderHeaderEquipmentIcon, SeatMapPassengers: Passengers, CheckoutInsurance: OrderInsuranceBlockSlotUpsale });
