import * as React from 'react';
import { SearchForm } from '@websky/core';
import { ThemeProvider } from '@websky/core/src/theme';
import theme from '../../theme/theme';
import '../../theme/variables.css';
import Head from '@websky/core/src/Head';
import { RenderersProvider } from '@websky/core/src/renderProps';
import { renders } from '../../renders';
import siteSearchFormTheme from '../../theme/siteSearchFormTheme';
import SearchFormWidget from './SearchFormWidget/SearchFormWidget';
var SearchFormComponent = function () {
    return (React.createElement(RenderersProvider, { value: renders },
        React.createElement(ThemeProvider, { value: theme },
            React.createElement(Head, null),
            React.createElement(RenderersProvider, { value: { SearchFormWithStore: SearchFormWidget } },
                React.createElement(ThemeProvider, { value: siteSearchFormTheme },
                    React.createElement(SearchForm, null))))));
};
export default SearchFormComponent;
