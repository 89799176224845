import { __assign } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchFormBase from '@websky/core/src/SearchForm/components/SearchForm/SearchForm';
import { setMultiCity } from '@websky/core/src/SearchForm/store/segments/actions';
var SearchFormWidget = React.memo(function (props) {
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(setMultiCity(false)); // ifly uses multiCity only in engine
    }, []);
    return React.createElement(SearchFormBase, __assign({}, props));
});
export default SearchFormWidget;
