import { __assign, __values } from "tslib";
import * as React from 'react';
import RootComponent from '@websky/core/src/SeatMap/components/SeatMapService/SeatMapService';
import SeatsInfo from './SeatsInfo/SeatsInfo';
import { cabinCodeComfortMap } from '@websky/core/src/SeatMap/components/SeatMap/utils';
import { SeatComfort } from '@websky/graphql';
var SeatMap = function (props) {
    var renderSeatsInfo = React.useCallback(function (services, segmentIndex) {
        var e_1, _a, e_2, _b, e_3, _c;
        var seatsBySegment = {};
        var isBusinessCabin = null;
        try {
            for (var _d = __values(props.seatMaps.segments[segmentIndex].decks), _e = _d.next(); !_e.done; _e = _d.next()) {
                var deck = _e.value;
                try {
                    for (var _f = (e_2 = void 0, __values(deck.rows)), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var row = _g.value;
                        if (row.seats.some(function (seat) { return seat.isAvailable; }) && !isBusinessCabin) {
                            isBusinessCabin = cabinCodeComfortMap.get(row.cabinCode) === SeatComfort.Comfort; // cabinCode === Y
                        }
                        var _loop_1 = function (seat) {
                            if (seat.rfisc && seat.isAvailable) {
                                var segmentInfo = services.find(function (service) { return service.rfisc === seat.rfisc; });
                                seatsBySegment[seat.rfisc] = __assign(__assign({}, seat), { name: segmentInfo.name, serviceImage: segmentInfo.serviceImage, description: segmentInfo.description, service: seat.seatService });
                            }
                        };
                        try {
                            for (var _h = (e_3 = void 0, __values(row.seats)), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var seat = _j.value;
                                _loop_1(seat);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return (React.createElement(SeatsInfo, { passenger: props.passengers[0], seatsByRfisc: seatsBySegment, showFreeSeat: isBusinessCabin, showComfort: false }));
    }, [props.passengers]);
    return React.createElement(RootComponent, __assign({}, props, { renderSeatsInfo: renderSeatsInfo }));
};
export default SeatMap;
