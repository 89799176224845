import SearchForm from './components/SearchForm/SearchFormWidget/SearchFormWidget.css';
export default {
    SearchForm: {
        SearchForm: {
            searchForm: SearchForm.container
        },
        Datepicker: {
            dates: SearchForm.datepicker__dates,
            dates__back: SearchForm.datepicker__dates__back,
            dates__icon: SearchForm.datepicker__dates__icon
        },
        Segment: {
            input: SearchForm.segment__input,
            departureInputWrapper: SearchForm.segment__departureInputWrapper,
            cell_location: SearchForm.segment__cell_location,
            cell_location_arrival: SearchForm.segment__cell_location_arrival,
            cell_date: SearchForm.segment__cell_date,
            alternative: SearchForm.segment__alternative,
            cell: SearchForm.segment__cell,
            wrapper: SearchForm.segment__wrapper,
            withMultiCity: SearchForm.withMultiCity
        },
        Passengers: {
            selector: SearchForm.passengers__selector,
            alternative: SearchForm.passengers__alternative,
            passengers: SearchForm.passengers__passengers
        },
        Currency: {
            selector: SearchForm.currency__selector
        },
        StartSearch: {
            startSearch: SearchForm.startSearch__startSearch,
            startSearch_iconMode: SearchForm.startSearch__startSearchIconMode
        },
        MultiCity: {
            multiCity: SearchForm.multiCity__multiCity
        },
        Value: {
            wrapper: SearchForm.value__wrapper,
            value: SearchForm.value
        },
        Controls: {
            controls: SearchForm.controls
        },
        Location: {
            switcher: SearchForm.location__switcher,
            switcher_alternative: SearchForm.switcher_alternative
        }
    },
    LocationAutocomplete: {
        Autocomplete: {
            autocomplete: SearchForm.autocomplete
        }
    }
};
